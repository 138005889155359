import React, { useState } from "react";
import logo from "../assets/logo.png";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaStar } from "react-icons/fa"; // Importing the star icon for premium users
import BackdropWrapper from "./modals/BackdropWrapper";
import ContactForm from "./modals/ContactForm";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import { auth } from "../authentication/firebase";
import { useSelector } from "react-redux"; // Importing useSelector to fetch subscription status
import CloseIcon from "@mui/icons-material/Close";
import Info from "./modals/Info";
import { CircularProgress } from "@mui/material"; 

export default function Navbar() {
  const [contactBackdrop, setContactBackdrop] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); 
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.user.userInfo);
  const userSubscription = useSelector((state) => state.user.subscription);
  const fullName = userInfo?.displayName || "";

  const [firstName, lastName] = fullName ? fullName.split(" ") : ["", ""];

  const displayName = lastName
    ? `${firstName} ${lastName.charAt(0)}.`
    : firstName || "Guest";

  const backdropHandler = () => {
    setContactBackdrop((current) => !current);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false); 
  };

  const handleSignOut = async (event) => {
     event.stopPropagation();
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await auth.signOut();
      navigate("/login");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); 
    }
  };

  const handleAvatarClick = (event) => {
    if (anchorEl) {
      handleMenuClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the dropdown menu
  };

  // Fetch subscription details from state (assuming it’s stored in Redux)
  const subscription = useSelector((state) => state.user.subscription); // Adjust the state path as necessary
  const isPremiumUser = subscription?.status === "active"; // Check if user is a premium subscriber

  return (
    <StyledNavbar>
      <BackdropWrapper
        open={contactBackdrop}
        smallSize={true}
        backdropHandler={backdropHandler}
        element={
          <Info
            heading={"Contact Us"}
            msg="For all inquires please contact: info@decanlys.com"
            backdropHandler={backdropHandler}
          />
        }
      />
      <div className="logo" onClick={() => navigate("/")}>
        <img src={logo} alt="Logo" />
        <h3>HighSchool<span>Mowers</span></h3>
      </div>
      <div className="nav-items">
        <div className="item">
          <Link to="/">Home</Link>
        </div>
        <div className="item">
          <Link to="/about">About</Link>
        </div>

        <div className="item">
          <Link to="/upgrade">Pricing</Link>
        </div>

        {auth.currentUser ? (
          <div className="avatar-section" onClick={handleAvatarClick}>
            <Avatar
              alt={auth.currentUser.email?.toUpperCase()}
              src="/broken-image.jpg"
              sx={{
                cursor: "pointer",
                width: 50,
                height: 50,
                marginRight: 1,
                background: "var(--secondary-color)",
              }}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              disableScrollLock
            >
              <Link to={`/p/${userInfo.userName}`}>
                <MenuItem onClick={handleMenuClose}>Profile Page</MenuItem>
              </Link>{" "}
              <Link to="/profile-setup">
                {" "}
                <MenuItem onClick={handleMenuClose}>Edit Profile</MenuItem>
              </Link>
              {userSubscription.status && (
                <Link to="/subscription-detail">
                  {" "}
                  <MenuItem onClick={handleMenuClose}>
                    Subscription Details
                  </MenuItem>
                </Link>
              )}
              <MenuItem
                onClick={handleSignOut}
                sx={{ display: "flex", alignItems: "center" }}
              >
                Logout
                {loading && (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "var(--primary-color)",
                      marginLeft: "6px",
                    }}
                  />
                )}
              </MenuItem>
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading && (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "var(--primary-color)",
                    }}
                  />
                )}
              </div> */}
            </Menu>
            {/* </Link> */}
          </div>
        ) : (
          <div className="login-section">
            <Link to="/login">Login</Link>
          </div>
        )}
      </div>

      {/* Hamburger icon */}
      <div className="nav-icon" onClick={toggleDrawer(true)}>
        <GiHamburgerMenu size={30} color="var(--text-light-color)" />
      </div>

      {/* Drawer for mobile view */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "280px", // Set your desired width here
          },
        }}
      >
        <DrawerHeader>
          <div className="drawer-logo" onClick={toggleDrawer(false)}>
            <CloseIcon htmlColor="white" fontSize="large" />
          </div>
        </DrawerHeader>
        <Divider />

        <List>
          {auth.currentUser ? (
            <>
              <MobileUser>
                <Link to={`/p/${userInfo.userName}`}>
                  <Avatar
                    alt={auth.currentUser.email}
                    src="/broken-image.jpg"
                    sx={{ marginRight: "5px", marginBottom: "0.7rem" }}
                  />
                  {/* <ListItemText primary={auth.currentUser.email} /> */}
                </Link>

                <h5 style={{ marginBottom: "0.6rem" }}>{displayName}</h5>
              </MobileUser>
              {/* Avatar and email inside the Drawer for logged-in users */}

              <Divider />
              <ListItem button onClick={() => handleNavigation("/")}>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button onClick={() => handleNavigation("/about")}>
                <ListItemText primary="About" />
              </ListItem>
              <ListItem button onClick={() => handleNavigation("/upgrade")}>
                <ListItemText primary="Pricing" />
              </ListItem>
              {userSubscription.status && (
                <ListItem
                  button
                  onClick={() => handleNavigation("/subscription-detail")}
                >
                  <ListItemText primary="Subscription Details" />
                </ListItem>
              )}
              {/* <ListItem button onClick={backdropHandler}>
                <ListItemText primary="Contact Us" />
              </ListItem> */}
              <Divider />
              {/* Sign out option at the bottom */}
              {/* <ListItem button onClick={handleSignOut}>
                <ListItemText primary="Log Out" />
              </ListItem> */}
            </>
          ) : (
            <>
              {/* Login link for non-logged-in users */}
              <ListItem button onClick={() => handleNavigation("/login")}>
                <ListItemText primary="Login" />
              </ListItem>
              <Divider />
              <ListItem button onClick={() => handleNavigation("/")}>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button onClick={() => handleNavigation("/about")}>
                <ListItemText primary="About" />
              </ListItem>

              <ListItem button onClick={() => handleNavigation("/upgrade")}>
                <ListItemText primary="Pricing" />
              </ListItem>
              {/* <ListItem
                button
                onClick={() => handleNavigation("/profile-page")}
              >
                <ListItemText primary="User Profile" />
              </ListItem> */}
              {/* <ListItem button onClick={backdropHandler}>
                <ListItemText primary="Contact Us" />
              </ListItem> */}
            </>
          )}
        </List>

        {auth.currentUser && (
          <Box>
            <ListItem
              button
              onClick={() => {
                navigate("/profile-setup");
              }}
            >
              <ListItemText primary="Edit Profile" />
            </ListItem>

            <ListItem button onClick={handleSignOut}>
              <ListItemText primary="Log Out" />
            </ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "var(--primary-color)",
                  }}
                />
              )}
            </div>
          </Box>
        )}
      </Drawer>

      {/* Menu for Avatar click */}
    </StyledNavbar>
  );
}

const StyledNavbar = styled.section`
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5%;
  box-shadow: 0px 0px 4px 2px var(--shadow-light);
  width: 100%;
  padding: 0 5%;

  h3 span {
    font-weight: inherit;
    font-size: inherit;
    color: var(--primary-color);
  }

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 200px;
    cursor: pointer;

    h2 {
      color: var(--text-light-color);
      font-weight: 700;
    }

    img {
      max-width: 60px;
      margin-right: 6px;
      height: auto;
    }
  }

  .nav-icon {
    display: block; // Show hamburger icon by default (for smaller screens)
    cursor: pointer;
  }

  .nav-items {
    display: none; // Hide navigation items by default for smaller screens
    align-items: center;
    justify-content: center;
    gap: 5%;
    white-space: nowrap;

    .item {
      cursor: pointer;
      a,
      h5 {
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--text-light-color);

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }

  .avatar-section {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .login-section {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--text-light-color);

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  @media (min-width: 769px) {
    .nav-icon {
      display: none; // Hide hamburger icon on larger screens
    }

    .nav-items {
      display: flex; // Show navigation items on larger screens
    }
  }

  @media (max-width: 400px) {
    .logo {
      h2 {
        font-size: var(--m-heading);
        font-weight: 600;
      }
    }
  }
`;

const MobileUser = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.5rem;
`;

// Styled component for the Drawer Header
const DrawerHeader = styled.div`
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: end;
  padding: 16px; // Padding for header
  background-color: var(--primary-color); // Match primary color
`;
